'use client';
import React from 'react';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BaseStretchedContainer from '@/shared/components/BaseStretchedContainer';
import Icon from '@/shared/components/Icon';
import { colors } from '@/lib/theme/palette';
import ButtonComponent from '@/shared/components/ButtonComponent';

export default function NotFound() {
  const { push } = useRouter();

  return (
    <main>
      <BaseStretchedContainer
        sx={{
          padding: {
            xs: '74px 24px 50px',
            sm: '48px 7% 100px',
            md: '48px 7% 100px',
            lg: '48px 8% 100px',
            xl: '80px 8% 100px',
          },
        }}
      >
        <Container disableGutters>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '298px',
                  sm: '490px',
                  md: '490px',
                  xl: '715px',
                },
              }}
            >
              <Image
                src="/404.svg"
                alt="Not found"
                unoptimized
                loading="lazy"
                width={715}
                height={321}
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                marginTop: {
                  xs: '40px',
                  sm: '32px',
                },
              }}
            >
              Oops, there&apos;s something wrong!
            </Typography>
            <ButtonComponent
              variant="outlined-primary"
              sx={{
                maxWidth: {
                  xs: '141px',
                  sm: '169px',
                  md: '182px',
                },
                padding: 0,
                alignItems: 'center',
                '& .MuiBox-root': {
                  marginRight: 0,
                },
                marginTop: {
                  xs: '24px',
                  sm: '48px',
                  xl: '56px',
                },
              }}
              prepend={
                <Icon
                  width={{ xs: 21 }}
                  height={{ xs: 21 }}
                  color={colors.blue}
                  icon="home"
                />
              }
              onClick={() => push('/')}
            >
              Back to home
            </ButtonComponent>
          </Box>
        </Container>
      </BaseStretchedContainer>
    </main>
  );
}
